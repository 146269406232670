import { Component, Input, Inject } from '@angular/core';
import { SurveyFormService } from './services/surveyform.services';
import { MASK, Survey, TYPE } from './surveyform/model';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  subject: string = '';
  message: string = '';
  loading: boolean = false;

  constructor(private surveyService: SurveyFormService) {
  }

  sendEmail(surveys: any[]) {
    let answer: string = '';
    this.loading = true;
    let idFormulario;
    let formName;
    let nome;
    let email;

    surveys.forEach(survey => {
      idFormulario = survey.item._data.idForm;
      formName = survey.item._data.formName;
      
      survey.item._data.questions.forEach(question => {
        if (question.name == 'nome_form') {nome = question.value;}
        if (question.name == 'email_form') {email = question.value;}

        if (question.options != undefined) {
          let nome = '';
          let nameSecudary = '';
          if (question.answerIndex != null) {
            let labelFirst = question.labelFirst != undefined ? question.labelFirst : '';
            nome =  labelFirst + '' + survey.item._data.questions[question.answerIndex].value;
          }
          if (question.nameSecudary != null && question.options[question.value] != undefined && question.options[question.value].placeHolder != undefined) {
            nameSecudary = ' [ ' + survey.item.fg.controls[question.nameSecudary].value + ' ]';
          }

          if (question.type == 'multiples_inputs') {
            answer += nome + question.label + ': ';
            var i;
            for (i = 0; i < question.options.length; i++) {
              answer += " [ " + question.options[i].placeHolder + " : "+ question.value[i] +" ] ";
            }
          } else {
            answer += nome + question.label + ': ' + (question.options.length == 0 ? question.label : question.options[question.value].label) + nameSecudary;
          }

          answer += '\n';
        } else {
          answer += question.label + ': '+ question.value;
          answer += '\n';
        }
      });
    });
    // console.log(answer);
    this.subject = "Form 3 ingles [preenchido]";
    this.message = answer;

    this.surveyService.leadToMautic(idFormulario, formName, nome, email).subscribe(() => {
      console.log('Sucess lead to Mautic');
    });

    this.surveyService.save(this.subject, this.message).subscribe(() => {
      console.log('E-mail enviado com sucesso');
      window.location.href = 'https://beonne.com/form_3_submitted_eng/'; // formulario 3 english
    });
  }

  voltarInicio(dados: any, index: number) {
    dados.active = index;
  }

  removeItens(dados: any, indexs: any[], isRemove: boolean) {
    if (isRemove) {
      indexs.forEach(item => {
        if(dados.questions[5].name == item.name) {
          dados.questions.splice(item.index, 1);
        }
      })
    }
  }


// Inicio - Formulario 3 english

dataForm3English: Survey = {
  label: '',
  english: true,
  idForm: 5,
  formName: 'Formulario 3 ingles',
  questions: [
    {
      name:'nome_form',
      placeHolder: 'Name',
      label: 'Before we go ahead, please type in your first name',
      tooltip: "Hi, It's a pleasure to assist you. I'm Patricia Minatti and will guide you for your best experience ;)",
      type: TYPE.TEXT,
      required: true,
    },
    {
      /* Pergunta 2 */
      name:'question2text',
      placeHolder: 'About your brand',
      label: "What's the name of your brand?",
      tooltip: "Don't worry, your info are safe with us and won't be shared at all",
      type: TYPE.TEXT,
      required: true
    },
    {
      /* Pergunta 51 */
      name:'question3text',
      placeHolder: 'About your product',
      label: 'Please describe your product in a few words',
      tooltip: "Don't worry, your info are safe with us and won't be shared at all",
      type: TYPE.TEXT,
      required: true
    },
    {
      /* Pergunta 4 */
      name:'question7radio',
      nameSecudary: 'question7text',
      label: 'Category',
      descricao: "Select your product's category",
      tooltip: "If you can't fit your product's category in any of the options below, feel free to specify it",
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Beauty & personal care'
        },
        {
          value: 1,
          label: 'Vitamins & suplements'
        },
        {
          value: 2,
          label: 'Sports accessories'
        },
        {
          value: 3,
          label: 'Home & kitchen',
        },
        {
          value: 4,
          label: 'Food in general',
        },
        {
          value: 5,
          placeHolder: 'Other',
          required: true,
          label: 'Other (please specify)',
        }
      ]
    },
    {
      /* Pergunta 5 */
      name:'question8radio',
      // nameSecudary: 'question8text',
      label: 'Your product on Amazon',
      descricao: 'How long have you been selling your product on Amazon for?',
      tooltip:'This information is important for projections purpose',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'My product has been listed on Amazon yet'
        },
        {
          value: 1,
          label: 'Less than 6 months'
        },
        {
          value: 2,
          label: 'Between 6 months and 1 year'
        },
        {
          value: 3,
          label: 'Between 1 year and 3 years',
        },
        {
          value: 4,
          label: 'More than 3 years',
        }
      ]
    },
    {
      /* Pergunta 51 */
      name:'questionTextDescption',
      placeHolder: 'ASIN number',
      label: 'Please provide your ASIN code (Amazon Standard Identification Number)',
      tooltip: 'It has 10 letters and/or numbers',
      type: TYPE.TEXT,
      required: true
    },
    {
      /* Pergunta 53 nova - essa e a proxima pergunta pulam se a pessoa não tem o produto disponivel na Amazon ainda */
      name:'question8radioTaxa1',
      // nameSecudary: 'question8text',
      label: 'Conversion rate',
      descricao: "The next question is about your product conversion % and it's available on Seller Central Report (business reports). Simply click on the top left menu on the 'detail page sales and traffic by Cil' and then 'Units session percentage'",
      type: TYPE.RADIO,
      descriptionMini: true,
      required: false,
      options: [
      ]
    },
    {
      /* Pergunta 52 nova - mesma regra de display da pergunta anterior*/
      name:'question10radio',
      // nameSecudary: 'question8text',
      label: 'Conversion rate',
      descricao: 'Please inform your product conversion percentage',
      tooltip: '',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: '0% to 4%'
        },
        {
          value: 1,
          label: '4% to 8%'
        },
        {
          value: 2,
          label: '8% to 15%'
        },
        {
          value: 3,
          label: '15% to 20%'
        },
        {
          value: 4,
          label: 'Above 20%',
        }
      ]
    },
    {
      /* Pergunta 7 */
      name:'question51radio1',
      // nameSecudary: 'question51text',
      label: 'Sales',
      descricao: 'How much is your product selling per month on Amazon?',
      tooltip: "Don't worry, this information will not be shared",
      type: TYPE.RADIO,
      required: false,
      options: [
        {
          value: 0,
          label: '$0 to $1 000 per month'
        },
        {
          value: 1,
          label: 'Between $1 000 and $5 000 per month'
        },
        {
          value: 2,
          label: 'Between $5 000 and $20 000 per month'
        },
        {
          value: 3,
          label: 'Between $20 000 and $50 000 per month'
        },
        {
          value: 4,
          label: 'Above de $50 000 per month',
        }
      ]
    },
    {
      /* Pergunta 7 */ 
      name:'question13radio',
      label: 'Amazon platform',
      descricao: 'How familiar are you with Amazon Seller Central?',
      tooltip: '',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Basic to none: I only know Amazon as a buyer'
        },
        {
          value: 1,
          label: 'Intermediate: I know how to list products and the basics of Amazon seller central'
        },
        {
          value: 2,
          label: 'Advanced: I have a good experience with selling products on Amazon and I master the seller central tool',
        }
      ]
    },
    {
      /* Pergunta 51 nova */
      name:'question51radio2',
      // nameSecudary: 'question51text',
      label: 'Investiment',
      descricao: 'How much do you intend to invest in your product on Amazon?',
      tooltip: '',
      type: TYPE.RADIO,
      required: false,
      options: [
        {
          value: 0,
          label: 'Above $10 000 per month'
        },
        {
          value: 1,
          label: 'Between $6 000 to $10 000 per month'
        },
        {
          value: 2,
          label: 'Between $4 000 to $6 000 per month'
        },
        {
          value: 3,
          label: 'Between $2 000 to $4 000 per month'
        },
        {
          value: 4,
          label: 'Below $2 000 per month',
        }
      ]
    },
    {
      /* Pergunta 9 */ 
      name:'question15text',
      placeHolder: 'Last question',
      label: 'Use this field to tell us anything that you consider important about your product',
      tooltip: 'Thank you!',
      type: TYPE.TEXT,
      required: true
    },
    {
      name:'email_form',
      placeHolder: 'type in your email',
      label: 'Type in your info and click on SUBMIT !',
      tooltip: 'Thank you! Once you submit your form, we will get back to you shortly',
      type: TYPE.TEXT,
      required: true,
    }
  ]
}

// Fim - Formulario 3 english


}
