import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class SurveyFormService {

  url = 'https://form3en.client.miracooldigital.com/envia_email.php';
  urlMautic = 'https://crmbeonne.miracooldigital.com/form/submit?formId=';

  constructor(private httpClient: HttpClient) { }
  
  save(subject:string, message:string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Access-Control-Allow-Headers':
        'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
      })
    }
    let postVars = {
        subject : subject,
        message : message
    };
    return this.httpClient.post<any>(this.url, postVars, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  leadToMautic(idForm: number, formName: string, nome: string, email: string) {
    let httpOptions = {
      headers: new HttpHeaders({ 
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, X-Requested-With',
        'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',    
      }), responseType: 'text' as 'json'
    }
    let postParams = {
      "mauticform": {
        nome_form : nome,
        email_form : email,
        post_id: idForm,
        referer_title: 'formulario',
        queried_id: idForm,
        messenger: idForm,
        form_id: idForm,
        formId : idForm,
        formName : formName,
      }
    };
    return this.httpClient.post<any>(this.urlMautic + idForm, postParams, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  // Manipulação de erros
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  };

}